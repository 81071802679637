import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VExpansionPanel,{staticClass:"custom-expansion-panel"},[_c(VExpansionPanelHeader,{staticClass:"headline",style:('background-color: ' + this.$vuetify.theme.currentTheme.primary)},[_c('div',[_c(VIcon,{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v(_vm._s(_vm.icon))]),_vm._v(_vm._s(_vm.header))],1)]),_c(VExpansionPanelContent,[_c(VRow,{attrs:{"dense":"","justify":"space-between"}},_vm._l((_vm.cards),function(card,i){return _c(VLayout,{key:i,staticClass:"px-2",attrs:{"sm":parseInt(12/_vm.cards.length, 10)}},[_c(VCard,{attrs:{"min-width":"100%"}},[_c(VCardTitle,[_vm._v(_vm._s(card.title))]),(card.items.length == 0)?_c(VCardSubtitle,[_vm._v(" "+_vm._s(_vm.$t('trucks.reports.currentStatus.noInfo'))+" ")]):_c(VCardText,[_c(VList,[_c(VListItemGroup,_vm._l((card.items),function(item,i){return _c('span',{key:i},[_c(VListItem,{staticClass:"pa-0"},[_c(VListItemContent,{staticClass:"py-0"},[_c(VListItemTitle,{staticClass:"py-1"},[_c('i',{staticClass:"fas fa-circle",style:(`color: ${item.color}; font-size: 11px !important;`)}),_vm._v(" "+_vm._s(item.truckName)+" "),_c('span',{staticClass:"item-time"},[_vm._v(_vm._s(item.time))])])],1)],1)],1)}),0)],1)],1)],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }