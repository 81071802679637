import { mapMutations } from 'vuex'
import i18n from '@/i18n'
import CustomExpansionComponent from './CustomExpansion/CustomExpansion.vue'
import SelectorDialog from '@/components/commons/selector/dialog/SelectorDialog.vue'
import SendCommandDialog from './send-command/SendCommandDialog.vue'
import OriginReport from '../origin-report/OriginReport.vue'
import fleetApi from '@/api/fleet.api'
import { getChildrenFromList } from '@/tools/functions'
import { filterRulesMapped } from '@/tools/filterRules'
import truckCurrentStatusReportService from '@/business/truckCurrentStatusReportService'

export default {
  name: 'TrucksCurrentStatusReport',
  components: {
    CustomExpansionComponent,
    SelectorDialog,
    SendCommandDialog,
    OriginReport
  },
  data: () => ({
    panel: [0, 1, 2, 3],
    itemsLists: {
      selectorDialogData: [],
      workshopContractorInItems: [],
      workshopContractorOutItems: [],
      workshopOwnInItems: [],
      workshopOwnOutItems: [],
      stateTravelingItems: [],
      stateProxIngenio1Items: [],
      stateOutOfServiceItems: [],
      harvestFrontInItems: [],
      harvestFrontOutItems: [],
      sugarmillNearItems: [],
      sugarmillInItems: [],
      sugarmillTrapicheItems: [],
      sugarmillWaitingForEmptyItems: []
    },
    harvestFronts: [],
    autoReport: false,
    autoReportMinutes: 1,
    intervalId: null,
    loadingSpinner: true,
    selectorDialogModel: {
      normalizer: (node) => {
        return {
          id: node.id,
          label: node.name,
          children: node.children
        }
      },
      data: [],
      selected: [],
      show: false,
      title: i18n.t('selector.selectionDialog.title', { entityName: i18n.t('trucks.title') }),
      singleSelect: false,
      selectAll: true,
      saveDisabled: false
    },
    originTableHeaders: [],
    originTableFilterRules: {},
    originTableData: [],
    tableDataKey: 'disengagement',
    loadingTable: false,
    tableDataAux: [],
    tableHeadersAux: [],
    tabSelected: 'tab-geofence',
    trucks: [],
    loadingGeofence: false,
    loadingWorkFront: false,
    loadingOrigin: false
  }),
  computed: {
    workshopCards () {
      return [
        {
          title: this.$i18n.t('trucks.reports.currentStatus.contractor') + ' - ' + this.$i18n.t('trucks.reports.currentStatus.in'),
          items: this.itemsLists.workshopContractorInItems
        },
        {
          title: this.$i18n.t('trucks.reports.currentStatus.contractor') + ' - ' + this.$i18n.t('trucks.reports.currentStatus.out'),
          items: this.itemsLists.workshopContractorOutItems
        },
        {
          title: this.$i18n.t('trucks.reports.currentStatus.own') + ' - ' + this.$i18n.t('trucks.reports.currentStatus.in'),
          items: this.itemsLists.workshopOwnInItems
        },
        {
          title: this.$i18n.t('trucks.reports.currentStatus.own') + ' - ' + this.$i18n.t('trucks.reports.currentStatus.out'),
          items: this.itemsLists.workshopOwnOutItems
        }
      ]
    },
    stateCards () {
      return [
        {
          title: this.$i18n.t('trucks.reports.currentStatus.traveling'),
          items: this.itemsLists.stateTravelingItems
        },
        {
          title: this.$i18n.t('trucks.reports.currentStatus.outOfService'),
          items: this.itemsLists.stateOutOfServiceItems
        }
      ]
    },
    harvestFrontsCards () {
      return [
        {
          title: this.$i18n.t('trucks.reports.currentStatus.in'),
          items: this.itemsLists.harvestFrontInItems
        },
        {
          title: this.$i18n.t('trucks.reports.currentStatus.out'),
          items: this.itemsLists.harvestFrontOutItems
        }
      ]
    },
    sugarmillCards () {
      return [
        {
          title: this.$i18n.t('trucks.reports.currentStatus.proxIngenio1'),
          items: this.itemsLists.stateProxIngenio1Items
        },
        {
          title: this.$i18n.t('trucks.reports.currentStatus.near'),
          items: this.itemsLists.sugarmillNearItems
        },
        {
          title: this.$i18n.t('trucks.reports.currentStatus.in'),
          items: this.itemsLists.sugarmillInItems
        },
        {
          title: this.$i18n.t('trucks.reports.currentStatus.trapiche'),
          items: this.itemsLists.sugarmillTrapicheItems
        },
        {
          title: this.$i18n.t('trucks.reports.currentStatus.waitingForEmpty'),
          items: this.itemsLists.sugarmillWaitingForEmptyItems
        }
      ]
    },
    autoReportSwitchLabel () {
      if (this.autoReport) {
        return this.$t('selector.autoReport.enabled')
      } else {
        return this.$t('selector.autoReport.disabled')
      }
    }
  },
  beforeDestroy () {
    this.closeReportDefer()
    clearInterval(this.intervalId)
    this.intervalId = null
  },
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    await this.getAllTrucks()
    await this.getGeofenceReport()
  },
  methods: {
    ...mapMutations('reportDefer', {
      commitVisible: 'commitVisible',
      closeReportDefer: 'closeReportDefer'
    }),
    openDialogAction () {
      this.selectorDialogModel.show = true
    },
    openCommandDialogAction () {
      this.$refs.SendCommandDialog.showDialog = true
    },
    saveDialogAction () {
      this.trucks.cleanAndUpdate(this.selectorDialogModel.selected)
      this.getGeofenceReport()
      this.selectorDialogModel.show = false
    },
    setTableFilterRules (headers) {
      this.originTableFilterRules = {}
      headers.forEach(header => {
        this.originTableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    },
    cancelDialogAction () {
      this.selectorDialogModel.show = false
    },
    async getAllTrucks () {
      const response = await fleetApi.getFlattened('TRANSPORT')
      this.selectorDialogModel.data.cleanAndUpdate(response.data)
      // Preselecciona para mostrar todos
      const selectedTrucks = []
      getChildrenFromList(this.selectorDialogModel.data, selectedTrucks)
      this.trucks.cleanAndUpdate(selectedTrucks.map(truck => truck.id))
      this.selectorDialogModel.selected.cleanAndUpdate(this.trucks)
    },
    /**
     * Obtener los datos para el reporte de geocercas
     */
    async getGeofenceReport () {
      this.loadingGeofence = true
      if (this.loadingSpinner) this.commitVisible({ visible: true })
      const events = await truckCurrentStatusReportService.getCurrentStatusReport(this.trucks)
      this.itemsLists = events
      if (this.loadingSpinner) this.commitVisible({ visible: false })
      this.loadingGeofence = false
    },
    /**
     * Obtener los datos para el reporte de frentes de trabajo
     */
    async getWorkFrontReport () {
      this.loadingWorkFront = true
      if (this.loadingSpinner) this.commitVisible({ visible: true })
      const workFrontdata = await truckCurrentStatusReportService.getWorkFrontReport(this.trucks)
      this.harvestFronts = Object.values(workFrontdata).sort(function (a, b) {
        return a.harvestFrontName.localeCompare(b.harvestFrontName, { ignorePunctuation: true })
      })
      if (this.loadingSpinner) this.commitVisible({ visible: false })
      this.loadingWorkFront = false
    },
    /**
     * Obtener los datos para el reporte de procedencia
     */
    async getOriginReport () {
      this.loadingOrigin = true
      if (this.loadingSpinner) this.commitVisible({ visible: true })
      this.loadingTable = true
      const originData = await truckCurrentStatusReportService.getOriginReport(this.trucks)
      this.originTableHeaders.cleanAndUpdate(originData.headers)
      this.originTableData.cleanAndUpdate(originData.data)
      this.loadingTable = false
      if (this.loadingSpinner) this.commitVisible({ visible: false })
      this.loadingOrigin = false
    },
    /**
     * Según el tab actual, obtener los datos
     */
    getTabReport () {
      switch (this.tabSelected) {
        case 'tab-geofence':
          if (!this.loadingGeofence) {
            this.getGeofenceReport()
          }
          break
        case 'tab-harvestFront':
          if (!this.loadingWorkFront) {
            this.getWorkFrontReport()
          }
          break
        case 'tab-origin':
          if (!this.loadingOrigin) {
            this.getOriginReport()
          }
          break
        default:
          console.error('Unknown tab')
      }
    },
    /**
     * Inicializar el proceso de auto-reporte
     */
    initAutoReport () {
      this.getTabReport()
      if (this.autoReport) {
        const that = this
        clearInterval(this.intervalId)
        this.intervalId = setInterval(
          () => {
            that.getTabReport()
          },
          (that.autoReportMinutes * 60 * 1000))
      } else {
        clearInterval(this.intervalId)
      }
    }
  },
  watch: {
    autoReport (newVal) {
      if (newVal) {
        this.initAutoReport()
      } else {
        clearInterval(this.intervalId)
      }
    },
    autoReportMinutes (newVal) {
      if (newVal > 0) {
        this.initAutoReport()
      } else {
        clearInterval(this.intervalId)
      }
    },
    tabSelected () {
      this.initAutoReport()
    }
  }
}
