import { FilterType, DataType } from '@colven/common-domain-lib/lib'
import i18n from '@/i18n'

const originHeaders = [
  {
    text: i18n.t('truckCurrentStateReport.origin.headers.truckName'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.string,
    value: 'truckName',
    width: 300
  },
  {
    text: i18n.t('truckCurrentStateReport.origin.headers.caneEquipmentName'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.string,
    value: 'caneEquipmentName',
    width: 300
  },
  {
    text: i18n.t('truckCurrentStateReport.origin.headers.tractorName'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.string,
    value: 'tractorName',
    width: 300
  },
  {
    text: i18n.t('truckCurrentStateReport.origin.headers.dischargeDate'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.string,
    value: 'dischargeDate',
    width: 300
  },
  {
    text: i18n.t('truckCurrentStateReport.origin.headers.driveUnitName'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.string,
    value: 'driveUnitName',
    width: 300
  },
  {
    text: i18n.t('truckCurrentStateReport.origin.headers.disengagementDate'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.string,
    value: 'disengagementDate',
    width: 300
  }
]

export default {
  originHeaders
}
