import { filterRulesMapped } from '@/tools/filterRules'

export default {
  name: 'OriginReport',
  components: {},
  props: {
    tableData: Array,
    tableHeaders: Array,
    tableDataKey: {
      type: String,
      required: false,
      default: 'disengagement'
    },
    loadingTable: {
      type: Boolean,
      required: false,
      default: false
    },
    disableRefreshButton: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data: () => ({
    originTableHeaders: [],
    originTableFilterRules: {},
    originTableData: []
  }),
  computed: {},
  beforeDestroy () {},
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    await this.loadTable()
  },
  methods: {
    async loadTable () {
      this.originTableHeaders.cleanAndUpdate(this.tableHeaders)
      this.setTableFilterRules(this.tableHeaders)
      this.originTableData.cleanAndUpdate(this.tableData)
    },
    setTableFilterRules (headers) {
      this.originTableFilterRules = {}
      headers.forEach(header => {
        this.originTableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    },
    refreshTable () {
      this.$emit('refreshTable')
    }
  },
  watch: {
    tableData () {
      this.loadTable()
    }
  }
}
