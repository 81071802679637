import SelectorTree from './tree/SelectorTree.vue'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: 'SelectorDialog',
  /**
   * MODELO
   *
   * normalizer: función para adaptar el componente a una estructura de datos diferente.
   * data: arreglo con los datos que pueden ser seleccionados.
   * selected: arreglo con los ids de los datos seleccionados.
   * show: booleano para mostrar/ocultar el diálogo.
   * title: título del diálogo (es una leyenda que indica el propósito del diálogo de selección).
   * singleSelect: booleano para indicar si el componente debe ser de selección simple o múltiple.
   * selectAll: booleano para el estado del checkbox para seleccionar todos los items.
   * saveDisabled: booleano para deshabilitar el botón para guardar.
   *
   * EVENTOS
   *
   * change: se lanza cuando se modifica el modelo.
   * save: se lanza cuando se hace click en el botón guardar del díalogo.
   * cancel: se lanza cuando se hace click en el botón cancelar del diálogo.
   *
   */
  model: {
    prop: 'model',
    event: 'change'
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    selectorId: {
      type: String,
      required: true
    }
  },
  components: {
    SelectorTree
  },
  data () {
    return {}
  },
  computed: {
  },
  mounted () {
  },
  methods: {
    save () {
      this.$emit('save', { data: this.model.data, selected: this.model.selected })
    },
    cancel () {
      this.$emit('cancel')
    }
  },
  watch: {
  }
}
