/* eslint-disable indent */
import truckApi from '@/api/truck.api'
import { getTimeOffset } from '@/tools/functions'
import { ReportConversionUtil } from '@colven/common-domain-lib/lib'
import truckCurrentStatusReportConstants from '../constants/truckCurrentStatusReport.constants'

/**
 * Obtener datos para el reporte de GEOCERCAS
 * @param {*} trucks
 * @returns
 */
const getCurrentStatusReport = async (trucks) => {
    try {
        const response = await truckApi.getCurrentState(trucks)
        return response.data
    } catch (error) {
        console.error(error)
        return {}
    }
}

/**
 * Obtener datos para el reporte de FRENTES DE COSECHA
 * @param {*} trucks
 * @returns
 */
const getWorkFrontReport = async (trucks) => {
    try {
        const response = await truckApi.getCurrentStateWorkFront(trucks)
        return processHarvestFrontData(response.data)
    } catch (error) {
        console.error(error)
        return {}
    }
}

/**
 * Procesar datos para el reporte de frentes de cosecha
 * @param {*} data
 * @returns
 */
const processHarvestFrontData = (data) => {
    const timeOffset = getTimeOffset()
    const keys = Object.keys(data)
    for (const key of keys) {
        const dataObject = data[key]
        data[key] = {
            harvestFrontId: dataObject.harvestFront._id,
            harvestFrontName: dataObject.harvestFront.name,
            // camiones vinculados
            vinculatedTrucks: dataObject.vinculatedTrucks.map(element => {
                const truckDate = ReportConversionUtil.applyTimezone(element.trackTimestamp, timeOffset)
                const result = {
                    id: element.truck._id,
                    truckName: element.truck.name,
                    truckTime: `${truckDate.dateString} ${truckDate.timeString}`,
                    truckColor: ReportConversionUtil.getTimestampColor(element.trackTimestamp),
                    caneEquipmentId: undefined,
                    caneEquipmentName: undefined,
                    caneEquipmentTime: undefined,
                    caneEquipmentColor: undefined,
                    caneEquipmentLoadedBoxes: undefined,
                    caneEquipmentTotalBoxes: undefined
                }
                // si el camión tiene un equipo cañero enganchado
                if (element.caneEquipment) {
                    const { _id, name, trackTimestamp, boxQuantity, boxes } = element.caneEquipment
                    const ceDate = trackTimestamp && ReportConversionUtil.applyTimezone(trackTimestamp, timeOffset)
                    result.caneEquipmentId = _id
                    result.caneEquipmentName = name
                    result.caneEquipmentTime = ceDate && `${ceDate.dateString} ${ceDate.timeString}`
                    result.caneEquipmentColor = trackTimestamp && ReportConversionUtil.getTimestampColor(trackTimestamp)
                    result.caneEquipmentLoadedBoxes = boxes
                    result.caneEquipmentTotalBoxes = boxQuantity
                }
                return result
            }),
            // equipos cañeros vinculados
            vinculatedCaneEquipments: dataObject.vinculatedCaneEquipments.map(element => {
                const ceDate = ReportConversionUtil.applyTimezone(element.trackTimestamp, timeOffset)
                return {
                    caneEquipmentId: element._id,
                    caneEquipmentName: element.name,
                    caneEquipmentTime: `${ceDate.dateString} ${ceDate.timeString}`,
                    caneEquipmentColor: ReportConversionUtil.getTimestampColor(element.trackTimestamp),
                    caneEquipmentLoadedBoxes: element.boxes,
                    caneEquipmentTotalBoxes: element.boxQuantity
                }
            })
        }
    }
    return data
}

/**
 * Obtener datos para el reporte de PROCEDENCIA
 * @returns
 */
const getOriginReport = async () => {
    try {
        const response = await truckApi.getCurrentStateOrigin()
        return {
            headers: truckCurrentStatusReportConstants.originHeaders,
            data: processOriginReportData(response.data)
        }
    } catch (error) {
        console.error(error)
        return {
            headers: truckCurrentStatusReportConstants.originHeaders,
            data: []
        }
    }
}

/**
 * Procesar datos para reporte de procedencia
 * @param {*} data
 * @returns
 */
const processOriginReportData = (data) => {
    const timeOffset = getTimeOffset()
    data.map(d => {
        // fecha de desvinculación del frente de cosecha
        const disengagementDate = d.tripDisengagement && ReportConversionUtil.applyTimezone(d.tripDisengagement, timeOffset)
        d.disengagementDate = disengagementDate && `${disengagementDate.dateString} ${disengagementDate.timeString}`

        // fecha de la descarga del tractor
        const dischargeDate = d.dischargeTrackTimestamp && ReportConversionUtil.applyTimezone(d.dischargeTrackTimestamp, timeOffset)
        d.dischargeDate = dischargeDate && `${dischargeDate.dateString} ${dischargeDate.timeString}`

        delete d.caneEquipment
    })
    return data
}

export default {
    getCurrentStatusReport,
    getWorkFrontReport,
    getOriginReport
}
