export default {
  name: 'CustomExpansionComponent',
  components: {
  },
  props: {
    icon: String,
    header: String,
    cards: Array
  },
  data: () => ({}),
  computed: {
  },
  mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
  },
  methods: {
  }
}
