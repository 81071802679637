import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"selector-dialog"},[(_vm.model.show)?_c(VDialog,{attrs:{"id":`modal_${_vm.selectorId}`,"width":"500"},on:{"click:outside":function($event){return _vm.cancel()}},model:{value:(_vm.model.show),callback:function ($$v) {_vm.$set(_vm.model, "show", $$v)},expression:"model.show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"pa-0"},[_c(VToolbar,{attrs:{"color":"primary","dark":""}},[_vm._v(" "+_vm._s(_vm.model.title)+" "),_c(VSpacer),_c(VIcon,{attrs:{"id":`modal_${_vm.selectorId}_closeBtn`},on:{"click":function($event){return _vm.cancel()}}},[_vm._v("close")])],1)],1),_c(VCardText,{staticClass:"pt-0 pb-2 px-2"},[_c('SelectorTree',{attrs:{"selectorId":_vm.selectorId},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1),_c(VCardActions,{staticClass:"pt-0 px-2 pb-2"},[_c(VSpacer),_c(VBtn,{attrs:{"id":`modal_${_vm.selectorId}_cancelBtn`,"width":"fit-content","color":"primary"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c(VBtn,{attrs:{"id":`modal_${_vm.selectorId}_saveBtn`,"width":"fit-content","color":"primary","disabled":_vm.model.saveDisabled},on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }