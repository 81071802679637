import Vue from 'vue'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { arraysEqual } from '@/tools/functions'

export default {
  name: 'SelectorTree',
  /**
   * MODELO
   *
   * normalizer: función para adaptar el componente a una estructura de datos diferente.
   * data: arreglo con los datos que pueden ser seleccionados.
   * selected: arreglo con los ids de los datos seleccionados.
   * show: booleano para mostrar/ocultar el diálogo.
   * title: título del diálogo (es una leyenda que indica el propósito del diálogo de selección).
   * singleSelect: booleano para indicar si el componente debe ser de selección simple o múltiple.
   * selectAll: booleano para el estado del checkbox para seleccionar todos los items.
   * saveDisabled: booleano para deshabilitar el botón para guardar.
   *
   * EVENTOS
   *
   * change: se lanza cuando se modifica el modelo.
   * save: se lanza cuando se hace click en el botón guardar del díalogo.
   * cancel: se lanza cuando se hace click en el botón cancelar del diálogo.
   *
   */
  model: {
    prop: 'model',
    event: 'change'
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    selectorId: {
      type: String,
      required: true
    }
  },
  components: {
    Treeselect
  },
  data () {
    return {
      search: '',
      sortValue: 'ascendent'
    }
  },
  computed: {
  },
  mounted () {
    if (this.model.data.length === 1 && this.model.singleSelect) {
      this.model.selected = this.model.data[0].id
    }
    this.sort()
  },
  methods: {
    sort () {
      this.sortValue = this.sortValue === 'descendent' ? 'ascendent' : 'descendent'
      this.sortFunction(this.model.data, this.sortValue)
    },
    sortFunction (list, sortType) {
      const that = this
      if (sortType === 'descendent') {
        list.sort(function sort (a, b) {
          const nameA = that.model.normalizer(a).label.toLowerCase()
          const nameB = that.model.normalizer(b).label.toLowerCase()
          if (nameA < nameB) {
            return -1
          } else if (nameA > nameB) {
            return 1
          } else {
            return 0
          }
        })
      } else {
        list.sort(function sort (a, b) {
          const nameA = that.model.normalizer(a).label.toLowerCase()
          const nameB = that.model.normalizer(b).label.toLowerCase()
          if (nameA > nameB) {
            return -1
          } else if (nameA < nameB) {
            return 1
          } else {
            return 0
          }
        })
      }
      list.forEach(item => {
        if (item.children && item.children.length > 0) {
          this.sortFunction(item.children, sortType)
        }
      })
    },
    save () {
      this.$emit('save', { data: this.model.data, selected: this.model.selected })
    },
    cancel () {
      this.$emit('cancel')
    },
    resetSearch(){
      const treeSelect = this.$refs[this.selectorId];
      if (treeSelect) {
        treeSelect.trigger.searchQuery = '';
      }
    },
    selectAllChanged () {
      if (!this.model.singleSelect) {
        if (this.model.selectAll) {
          const firstLevelIds = this.model.data.map(item => this.model.normalizer(item).id)
          this.model.selected = []
          this.model.selected.cleanAndUpdate(firstLevelIds)
        } else {
          this.model.selected = []
        }
      }
    },
    sortSelections () {
      const selections = this.model.selected || []
      this.model.selected = selections.sort()
    },
    checkSelectAll () {
      const that = this
      Vue.nextTick(function () {
        if (!that.model.singleSelect) {
          const firstLevelIds = that.model.data.map(item => that.model.normalizer(item).id)
          firstLevelIds.sort()
          that.sortSelections()
          that.model.selectAll = arraysEqual(firstLevelIds, that.model.selected)
        }
      })
    }
  },
  watch: {
    'model.show': function (val) {
      if (val) {
        this.checkSelectAll()
      }
    }
  }
}
