import service from '@/middleware'

const truckApi = {

  async getSummaryReportTruckDetails (thingId, from, to, timeFormat) {
    return service.http.post('/api/truck/summary-report/truckDetails', {
      thingId,
      from,
      to,
      timeFormat
    })
  },

  async getCurrentState (trucks) {
    const result = await service.http.post('/api/truck/current-state', { trucks })
    return result
  },

  async getCurrentStateWorkFront (trucks) {
    const result = await service.http.post('/api/truck/current-state-work-fronts', { trucks })
    return result
  },

  async getCurrentStateOrigin () {
    const result = await service.http.post('/api/truck/current-state-origin')
    return result
  },

  async sendCommandOutOfService (trucks) {
    return service.http.post('/api/truck/send-command/out-of-service', { trucks })
  },

  async sendCommandInService (trucks) {
    return service.http.post('/api/truck/send-command/in-service', { trucks })
  },

  async getSummaryReportDeferred (filters) {
    const body = {
      reportQueryFilters: {
        thingsId: filters.thingsId,
        fleetsId: filters.fleetsId,
        from: filters.from,
        to: filters.to,
        timeFormat: filters.timeFormat,
        route: filters.route
      },
      filters
    }
    const result = await service.http.post('/api/truck/summary-report/deferred', body)
    return result
  },

  async getSummaryReportNotDeferred (thingsId, fleetsId, from, to, timeFormat) {
    const result = await service.http.post('/api/truck/summary-report/not-deferred',
      { thingsId,
        fleetsId,
        from,
        to,
        timeFormat
      })
    return result
  },

  async getOriginReport (reportKey, timeFormat) {
    const result = await service.http.post('/api/truck/summary-report/origin', { reportKey, timeFormat })
    return result
  },

  async getOriginReportDirect (thingsId, from, to, timeFormat) {
    const result = await service.http.post('/api/truck/summary-report/origin/direct', { thingsId, from, to, timeFormat })
    return result
  },

  async getHistoricalReportDeferred (thingsId, from, to, things, route, filters) {
    const body = {
      reportQueryFilters: {
        things,
        thingsId,
        from,
        to,
        route
      },
      filters
    }
    const result = await service.http.post('/api/truck/historical-report/deferred', body)
    return result
  },

  async getHistoricalReportNotDeferred (thingsId, from, to, things, timeFormat) {
    const result = await service.http.post('/api/truck/historical-report/not-deferred',
      { thingsId,
        from,
        to,
        thingsData: things,
        timeFormat
      })
    return result
  }
}

export default truckApi
