import service from '@/middleware'

const fleetApi = {
  async getNested(sectorKey) {
    const result = await service.http.get('/api/fleet/nested', { params: { sectorKey } })
    return result
  },
  async getFlattened(sectorKey) {
    const result = await service.http.get('/api/fleet/flattened', { params: { sectorKey } })
    return result
  },
  async getAll() {
    const { data } = await service.http.get('/api/fleet/getAll')
    return data;
  }
}

export default fleetApi
