// import i18n from '@/i18n'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { arraysEqual } from '@/tools/functions'
import truckApi from '../../../../../api/truck.api'
import { SnackbarStyle } from '@/constants/constants'
import { mapActions } from 'vuex'
import { getItemsById, getChildrenFromList } from '../../../../../tools/functions'

export default {
  name: 'SendCommandDialog',
  props: {
    normalizer: {
      type: Function,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  },
  components: {
    Treeselect
  },
  data () {
    return {
      showDialog: this.show,
      selected: [],
      selectAll: false,
      search: '',
      disabled: true,
      sortValue: 'descendent',
      spinner: false
    }
  },
  computed: {
  },
  mounted () {
    console.log('mounted', this.data)
  },
  methods: {
    ...mapActions({
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar'
    }),
    sort () {
      this.sortValue = this.sortValue === 'descendent' ? 'descendent' : 'ascendent'
      this.sortFunction(this.data, this.sortValue)
    },
    closeDialog () {
      this.showDialog = false
      this.selected = []
    },
    sendOutOfService () {
      truckApi.sendCommandOutOfService(this.getTruckIds()).then(() => {
        this.showSnackbar({ visible: true, text: this.$t('trucks.reports.currentStatus.commandSentSuccess'), timeout: 6000, style: SnackbarStyle.SUCCESS })
      })
      this.closeDialog()
    },
    sendInService () {
      truckApi.sendCommandInService(this.getTruckIds()).then(() => {
        this.showSnackbar({ visible: true, text: this.$t('trucks.reports.currentStatus.commandSentSuccess'), timeout: 6000, style: SnackbarStyle.SUCCESS })
      })
      this.closeDialog()
    },
    /**
     * Obtener los ids de los camiones seleccionados
     * @returns
     */
    getTruckIds () {
      const nodes = []
      getItemsById(this.data, this.selected, nodes)
      const trucks = []
      getChildrenFromList(nodes, trucks)
      return trucks.map(truck => truck.id)
    },
    sortFunction (list, sortType) {
      if (sortType === 'descendent') {
        list.sort(function sortFunction (a, b) {
          const nameA = a.name.toLowerCase()
          const nameB = b.name.toLowerCase()
          if (nameA < nameB) {
            return -1
          } else if (nameA > nameB) {
            return 1
          } else {
            return 0
          }
        })
      } else {
        list.sort(function sortFunction (a, b) {
          const nameA = a.name.toLowerCase()
          const nameB = b.name.toLowerCase()
          if (nameA > nameB) {
            return -1
          } else if (nameA < nameB) {
            return 1
          } else {
            return 0
          }
        })
      }
      list.forEach(item => {
        if (item.children && item.children.length > 0) {
          this.sortFunction(item.children, sortType)
        }
      })
    },
    selectAllClicked () {
      if (this.selectAll) {
        const firstLevelIds = this.data.map(item => this.normalizer(item).id)
        this.selected.cleanAndUpdate(firstLevelIds)
      } else {
        this.selected.clean()
      }
    }
  },
  watch: {
    selected (nextVal, currentVal) {
      if (!this.singleSelect) {
        this.selectAll = currentVal.length > 0 && nextVal.length > 0 && arraysEqual(currentVal, nextVal)
      }
    }
  }
}
